<template>
    <div>
        <!-- <div class="image-container" v-if="file.mime_type === 'image/jpeg' && file.element_texts[0].text === 'audio-beeld'"> -->
        <div class="image-container" v-if="file.mime_type === 'image/jpeg' || file.mime_type === 'image/png'">
            <img class = "audio-image"  v-bind:src="file.file_urls.original" width="100%" height="auto" >
        </div>
        <!-- <div class="audio-container" v-if="file.mime_type === 'audio/mpeg' && file.element_texts[0].text === ('audio-' + lang)"> -->
        <!-- <div class="audio-container" v-if="file.mime_type === 'audio/mpeg' && audioFile.substr(5,2) === lang.toUpperCase()"> -->
        <div class="audio-container" v-if="file.mime_type === 'audio/mpeg' && audioLabel === ('audio-' + lang)">
            <audio v-if="browserName !== 'ios' && browserOs !== 'iOS'" v-bind:autoplay="isAutoPlay" @timeupdate="updateProgress" @ended="audioEnded" @playing="audioPlaying">
                <source  v-bind:src="file.file_urls.original" type="audio/mpeg">
            </audio>
            <audio v-else @timeupdate="updateProgress" @ended="audioEnded">
                <source  v-bind:src="file.file_urls.original" type="audio/mpeg">
            </audio>
            <div class="control-container">
                <div v-if="bkwSkipable === true" class="button-skipbackward" @click="skipAudioBackward"><img src="/assets/buttons/skip-bkw.png"></div>
                <div v-if="isPlaying === false" class="button-play-pause" @click="playAudio"><img src="/assets/buttons/play.png"></div>
                <div v-if="isPlaying === true" class="button-play-pause" @click="pauseAudio"><img src="/assets/buttons/pause.png"></div>
                <div v-if="fwdSkipable === true" class="button-skipforward" @click="skipAudioForward"><img src="/assets/buttons/skip-fwd.png"></div>
                <div class="progress-container">
                    <div class="progress-indicator" v-bind:style="{width: parseInt((audioProgress / audioDuration) * 100) + '%'}"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
// const { detect } = require('detect-browser');
// const browser = detect();
// const appConfig = require('../../public/config/app.json');
export default {
    name: "ObjectFileItem",
    props: ["file", "lang"],
    data() {
        return {
            browserOs: null,
            browserName: null,
            audioProgress: 0,
            audioDuration: 1,
            audio: null,
            audioFile: '',
            audioLabel: '',
            isPlaying: false,
            fwdSkipable: false,
            bkwSkipable: false,
            isAutoPlay: false
        }
    },
    methods: {
        playAudio: function() {
            // console.log('playAudio');
            let player = document.querySelector('audio');
            if(player !== null) {
                this.isPlaying = true;
                player.play();
            } else {
                console.log('audio element is null!');
            }
        },
        pauseAudio() {
            // if(this.audio !== null) {
            //     this.isPlaying = false;
            //     this.audio.pause();
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                this.isPlaying = false;
                player.pause();
            }
        },
        skipAudioForward() {
            // if(this.audio !== null) {
            //     this.audio.currentTime = this.audio.currentTime + 5;
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                player.currentTime = player.currentTime + 5;
            }
        },
        skipAudioBackward() {
            // if(this.audio !== null) {
            //     if (this.audio.currentTime > 5) {
            //         this.audio.currentTime = this.audio.currentTime - 5;
            //     } else {
            //         this.audio.currentTime = 0;
            //     }
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                if (player.currentTime > 5) {
                    player.currentTime = player.currentTime - 5;
                } else {
                    player.currentTime = 0;
                }
            }
        },
        updateProgress() {
            // this.audioProgress = this.audio.currentTime;
            // this.audioDuration = this.audio.duration;
            // this.bkwSkipable = true;
            // if(this.audio.duration - this.audio.currentTime > 5) {
            //     this.fwdSkipable = true;
            // } else {
            //     this.fwdSkipable = false;
            // }
            let player = document.querySelector('audio');
            this.audioProgress = player.currentTime;
            this.audioDuration = player.duration;
            this.bkwSkipable = true;
            if(player.duration - player.currentTime > 5) {
                this.fwdSkipable = true;
            } else {
                this.fwdSkipable = false;
            }
        },
        audioEnded() {
            this.isPlaying = false;
            this.bkwSkipable = false;
        },
        audioPlaying() {
            this.isPlaying = true;
        }
    },
    created() {
        axios.get('/config/app.json')
            .then((res) => {
                let appConfig = res.data;
                const { detect } = require('detect-browser');
                const browser = detect();
                this.browserOs = browser.os;
                this.browserName = browser.name;
                this.isAutoPlay = appConfig.autoplay;
                var audioPathSplit = this.file.original_filename.split('/');
                this.audioFile = audioPathSplit[audioPathSplit.length - 1];
                if(this.file.element_texts[0]) {
                    this.audioLabel = this.file.element_texts[0].text;
                }
                console.log('file:' + this.audioFile);
                // testje
                this.audio = document.querySelector('audio');
                if(this.audio !== null) {
                    console.log('audio element gevonden:'+this.audio.toString());
                    console.log('browser: ' + this.browserName + ' - ' + this.browserOs);
                } else {
                    console.log('geen audio elementen gevonden')
                }

            })
            .catch((err) => { console.log('Object fileItem config error: ' + err) });
    },
    beforeDestroy() {
        if(this.audio !== null) {
        this.audio.pause();
        console.log('audio geforceerd gestopt!');
        }
    },
    mounted() {
        // this.audio = document.querySelector('audio');
        // if(this.audio !== null) {
        //     console.log('audio element gevonden:'+this.audio);
        //     console.log('browser: ' + this.browserName + ' - ' + this.browserOs);
        // } else {
        //     console.log('geen audio elementen gevonden')
        // }
    }
}
</script>

<style scoped>
    .file-item {
        background: #f4f4f4;
        /* padding: 10px; */
        border-bottom: 1px #ccc dotted;
    }

    audio {
        width: 100%;
    }

    .audio-container {
        position: fixed;
        bottom: 50px;
        width: 100%;
    }

    .audio-image {
        width: 100%;
        height: auto;
    }

    .image-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        /* margin-top: 80px; */
        /* height: 60%; */
        overflow: hidden;
    }
    .control-container {
        position: relative;
        width:90%;
        height: 70%;
        margin-left: 5%;
        margin-right: 5%;
        /* background-color: #ffffff55; */
        background-color: #666666;
        border: 1px solid #ffffff22;
        /* border-radius: 2%; */
        padding: 5px;
    }
    .button-skipforward {
        position: absolute;
        top: 0;
        right: 5%;
        width: 60px;
        height: 50px;
    }
    .button-skipforward img {
        object-fit: contain;
        max-width: 100%;
    }
    .button-skipbackward {
        position: absolute;
        top: 0;
        left: 5%;
        width: 60px;
        height: 50px;
    }
    .button-skipbackward img {
        object-fit: contain;
        max-width: 100%;
    }
    .button-play-pause {
        /* position: absolute; */
        top: 0;
        width: 45px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
    }
    .button-play-pause img {
        object-fit: contain;
        max-width: 100%;
    }
    .progress-container {
        background-color: gray;
        width: 96%;
        height: 5px;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 3px;
    }
    .progress-indicator {
        background-color: white;
        height: 100%;
    }
</style>