<template>
  <div id="app">
    <!-- <Header /> -->
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
// import Header from './components/layouts/Header'
export default {
  name: "app",
  components: {
    // Header
  }
}
</script>
<style>
@font-face {
    font-family: 'robotoregular';
    src: url('/assets/fonts/roboto/Roboto-Regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('/assets/fonts/roboto/Roboto-Bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    src: url('/assets/fonts/roboto/Roboto-Medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body {
  background-color: black;
}
#app {
  font-family: 'robotoregular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  z-index: 999;
  background-color: black;
}
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .sensors .flag {
    position: absolute;
    right: 5%;
    top: 10px;
    width: auto;
    height: 60px;
  }
  .header .divider {
    width: 100%;
    position: absolute;
    top: 70px;
  }
  .header .divider hr {
    /* margin-top: 5px; */
    width: 90%;
  }
</style>
