<template>
  <div id="app">
    <div class="header">
      <div class="sensors"><center>
          <img v-if="browseActive && browseCurrent > 0" src="/assets/buttons/start-button-diap.png" alt="Start browse" @click="showStart">
        <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
        <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
        <img v-if="bbActive" src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
        <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
      </center></div>
        <img v-if="langId !== 'x'" class="flag" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" class="flag" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
      <div class="home"><img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome"></div>
      <div class="divider"><center><hr></center></div>
    </div>
    <div class="object-container">
      <div class="group-title" v-if="groupId && groupId !== '0'" @click="getGroupObjects(groupId)">&lt;-{{ groupTitle }}</div>
      <ObjectFiles v-bind:files="files" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
      <div v-if="browseActive" class="object-browse">
        <div class="object-prev-container">
          <div v-if="browsePrevious" class="object-prev" @click="showPrevious">{{ labelPrevious }}</div>
        </div>
        <div class="object-next-container">
          <div v-if="browseNext" class="object-next" @click="showNext">{{ labelNext }}</div>
        </div>
      </div>
      <div class="object-title">{{ objectTitle }}</div>
      <div v-html="objectDescription" class="object-description"></div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import ObjectFiles from '../components/ObjectFiles';
import axios from 'axios';
import router from '../router';
// import configData from '../../public/config/app.json';
export default {
  name: 'ObjectPage',
  components: {
    ObjectFiles
  },
  props: ['langId', 'groupId', 'objectId'],
  data() {
    return {
      apiKey: null,
      apiUrl: null,
      apiCollection: null,
      files: [],
      objectData: [],
      browserOs: null,
      browserName: null,
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      objectTitle: '',
      objectDescription: '',
      browseActive: false,
        browseStart: false,
      browseMax: 0,
      browseCurrent: 0,
      browseNext: false,
      browsePrevious: false,
      labelNext: 'Volgende',
      labelPrevious: 'Vorige',
      groupTitle: '',
        languageX: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    // showHome: function() {
    //   router.push('/intro/'+this.langId);
    // },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    getGroupObjects: function(tagIn) {
      router.push('/group/' + tagIn + '/' + this.langId);
    },
    showNext: function () {
      let tagnrNext = this.browseCurrent + 1;
      let tagStringNext = '';
      if(tagnrNext < 10) {
        tagStringNext = 'audio-0' + tagnrNext;
      } else {
        tagStringNext = 'audio-' + tagnrNext;
      }
      axios.get(this.apiUrl + 'items?tags='+tagStringNext+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
                let objectId = res.data[0].id;
                router.push('/object/'+this.langId+'/'+objectId);
              }
          )
          .catch(err => console.log(err));
    },
    showPrevious: function () {
      let tagnrPrev = this.browseCurrent - 1;
      let tagStringPrev = '';
      if(tagnrPrev < 10) {
        tagStringPrev = 'audio-0' + tagnrPrev;
      } else {
        tagStringPrev = 'audio-' + tagnrPrev;
      }
      axios.get(this.apiUrl + 'items?tags='+tagStringPrev+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
                let objectId = res.data[0].id;
                router.push('/object/'+this.langId+'/'+objectId);
              }
          )
          .catch(err => console.log(err));
    },
      showStart: function() {
          if(this.browseStart && this.browseActive) {
              // open first subject in audiotour
              axios.get(this.apiUrl + 'items?tags=audio-00&collection=' + this.apiCollection + '&key=' + this.apiKey)
                  .then((res) => {
                          let objectId = res.data[0].id;
                          router.push('/object/'+this.langId+'/'+objectId);
                      }
                  )
                  .catch(err => console.log(err));
          } else {
              router.push('/intro/' + this.langId);
          }
      }
  },
  created() {
      console.log('objectpage started...');
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      this.apiUrl = configData.api;
      this.apiKey = configData.api_key;
      this.apiCollection = configData.api_collection;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.groupsActive = configData.groups;
        this.browseActive = configData.browse;
        this.browseStart = configData.browse_start;
      this.browseMax = configData.browse_max;
      this.languageX = configData.language_x;
      // labels volgende / vorige knop
      if(this.langId === 'nl') {
        if(configData.label_button_next.nl !== '') {
          this.labelNext = configData.label_button_next.nl;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.nl !== '') {
          this.labelPrevious = configData.label_button_previous.nl;
        } else {
          this.labelPrevious = '<';
        }
      }
      if(this.langId === 'de') {
        if(configData.label_button_next.de !== '') {
          this.labelNext = configData.label_button_next.de;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.de !== '') {
          this.labelPrevious = configData.label_button_previous.de;
        } else {
          this.labelPrevious = '<';
        }
      }
      if(this.langId === 'en') {
        if(configData.label_button_next.en !== '') {
          this.labelNext = configData.label_button_next.en;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.en !== '') {
          this.labelPrevious = configData.label_button_previous.en;
        } else {
          this.labelPrevious = '<';
        }
      }
        if(this.langId === 'fr') {
            if(configData.label_button_next.fr !== '') {
                this.labelNext = configData.label_button_next.fr;
            } else {
                this.labelNext = '>';
            }
            if(configData.label_button_previous.fr !== '') {
                this.labelPrevious = configData.label_button_previous.fr;
            } else {
                this.labelPrevious = '<';
            }
        }
        if(this.langId === 'x') {
            if(configData.label_button_next.x !== '') {
                this.labelNext = configData.label_button_next.x;
            } else {
                this.labelNext = '>';
            }
            if(configData.label_button_previous.x !== '') {
                this.labelPrevious = configData.label_button_previous.x;
            } else {
                this.labelPrevious = '<';
            }
        }
      axios.get(this.apiUrl + 'files?item='+this.objectId+'&key=' + this.apiKey)
              .then((res) => {
                this.files = res.data
              })
              .catch(err => console.log(err));

      // if applicable: name group
      axios.get(this.apiUrl + 'items?item_type=19&collection=' + this.apiCollection + '&key=' + this.apiKey)
      .then((tagResult) => {
        if(tagResult && tagResult.data.length > 0) {
          tagResult.data.forEach((groupRecord) => {
            groupRecord.element_texts.forEach((text_element) => {
              if(text_element.element.name === 'Tag naam' && text_element.text === this.groupId) {
                groupRecord.element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId) {
                    this.groupTitle = text_element.text;
                    console.log('group title: ' + this.groupTitle);
                  }
                });
              }
            });
          });
          tagResult.data.element_texts.forEach((text_element) => {
            if(text_element.element.name === 'Titel_' + this.langId) {
              this.groupTitle = text_element.text;
              console.log('group title: ' + this.groupTitle);
            }
          })
        }
      })
      .catch((groupError) => {
        console.log('Group title error: ' + groupError);
      });
      // title, text and tags
      axios.get(this.apiUrl+ 'items/'+this.objectId+'?key=' + this.apiKey)
              .then((resObject) => {
                this.objectData = resObject.data;
                this.objectData.element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.objectTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.objectDescription = text_element.text;
                  }
                  this.objectData.tags.forEach((tagFound) => {
                    if(tagFound.name.substr(0,6) === 'audio-') {
                      let tagNumber = tagFound.name.split('-')[1];
                      this.browseCurrent = parseInt(tagNumber);
                      if(this.browseCurrent > 0 && this.browseCurrent <= this.browseMax) {
                        this.browsePrevious = true;
                      }
                      if(this.browseCurrent < this.browseMax) {
                        this.browseNext = true;
                      }
                    }
                  });
                });
              })
              .catch(errObject => console.log(errObject));
    })
    .catch((err) => { console.log('config error: ' + err) });
  },
  beforeDestroy() {
    this.files=null;
    delete this.files;
  }
}
</script>

<style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    line-height: 1.4;
    background-color: black;
  }
  .header .home {
    position: absolute;
    padding: 5px;
    height: 60px;
    top: 0px;
    left: 5%;
  }
  .header .home img {
    height: 60px;
    width: auto;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }

  .btn {
    display: inline-block;
    border: none;
    background: #555;
    color: #fff;
    padding: 7px 20px;
    cursor: pointer;
  }

  .btn:hover {
    background: #666;
  }

  .object-container {
    padding-top: 80px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: white;
  }
  .group-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 2em;
  }
  .object-container-browse {
    padding-top: 120px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: white;
  }
  .object-title {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .object-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .object-browse {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #444444;
  }
  .object-next-container {
    padding: 5px;
    width: 50%;
  }
  .object-next {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background-color: #666666;
  }
  .object-prev-container {
    padding: 5px;
    width: 50%;
  }
  .object-prev {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background-color: #666666;
  }
  .stream-container {
    width: 90%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .stream-container iframe {
    width: 100%;
    height: 200px;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    color: white;
    text-align: center;
    background-color: black;
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
