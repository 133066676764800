<template>
  <div id="app">
    <div class="header">
      <div class="sensors">
        <center>
          <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
          <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
          <img v-if="bbActive" src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
          <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
        </center>
      </div>
      <div class="home"><img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome"></div>
        <img v-if="langId !== 'x'" class="flag" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" class="flag" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
        <div class="divider"><center><hr></center></div>
      </div>
      <div class="object-container">
        <ObjectFiles v-bind:files="introFiles" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
        <div class="intro-title">{{ introTitle }}</div>
        <div v-html="introDescription" class="intro-description"></div>
      </div>
      <div class="footer">
        <hr>
        <div class="copy-right">&copy; Easycast Audio Tour</div>
      </div>
  </div>
</template>

<script>
import router from '../router';
import axios from 'axios';
import ObjectFiles from '../components/ObjectFiles';

export default {
  name: 'Welcome',
  components: {
    ObjectFiles
  },
  props: ["langId"],
  data() {
    return {
      browserOs: null,
      browserName: null,
      introData: [],
      introFiles: [],
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      introTitle: '',
      introDescription: '',
        languageX: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    }
  },
  created() {
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      const apiUrl = configData.api;
      const apiKey = configData.api_key;
      const apiCollection = configData.api_collection;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.groupsActive = configData.groups;
      this.languageX = configData.language_x;

        axios.get(apiUrl + 'items?tags=intro&collection=' + apiCollection + '&key=' + apiKey)
              .then((res) => {
                this.introData = res.data;
                // console.log(JSON.stringify(this.introData[0]));
                this.introData[0].element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.introTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.introDescription = text_element.text;
                  }
                });
                // get audiofile(s)
                axios.get(apiUrl + 'files?item='+this.introData[0].id+'&key=' + apiKey)
                        .then((resFiles) => {
                          // console.log(JSON.stringify(resFiles.data));
                          this.introFiles = resFiles.data;
                        })
                        .catch();
              })
              .catch();
    })
    .catch((err) => { console.log('config error: ' + err) });
  },
  beforeDestroy() {
    this.introFiles=null;
    delete this.introFiles;
  }
}
</script>

<style scoped>
.intro {
    width: 100%;
}
  .intro {
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .object-container {
    padding-top: 80px;
    width: 100%;
    height: 100%;
    padding-bottom: 140px;
    color: white;
  }
  .header .intro {
    position: absolute;
    padding: 5px;
    height: 60px;
    top: 0px;
    left: 5%;
  }
  .header .intro img {
    height: 60px;
    width: auto;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }
  .intro-title {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .intro-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
    .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    color: white;
    text-align: center;
    background-color: black;
}
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
