import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Intro from '../views/Intro'
import Instruction from '../views/Instruction'
import Welcome from '../views/Welcome'
import ObjectPage from '../views/ObjectPage'
import QRScanner from '../views/QRScanner'
import KeyPad from '../views/KeyPad'
import ButtonBoard from '../views/ButtonBoard'
import Groups from "@/views/Groups";
import GroupItems from "@/views/GroupItems";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/welcome/:langId',
    name: 'welcome',
    component: Welcome,
    props: true
  },
  {
    path: '/intro/:langId',
    name: 'intro',
    component: Intro,
    props: true
  },
  {
    path: '/instruct/:langId',
    name: 'instruct',
    component: Instruction,
    props: true
  },
  {
    path: '/object/:langId/:groupId/:objectId',
    name: 'object',
    component: ObjectPage,
    props: true
  },
  {
    path: '/object/:langId/:objectId',
    name: 'object',
    component: ObjectPage,
    props: true
  },
  {
    path: '/qr/:langId',
    name: 'qr',
    component: QRScanner,
    props: true
  },
  {
    path: '/key/:langId',
    name: 'key',
    component: KeyPad,
    props: true
  },
  {
    path: '/buttons/:langId',
    name: 'buttons',
    component: ButtonBoard,
    props: true
  },
  {
    path: '/groups/:langId',
    name: 'groups',
    component: Groups,
    props: true
  },
  {
    path: '/group/:groupId/:langId',
    name: 'group',
    component: GroupItems,
    props: true
  }

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
