<template>
  <div id="app">
    <div class="header">
      <div class="home"><img src="/assets/buttons/home-wit-schaduw.png" alt="home" @click="showWelcome"></div>
      <div class="sensors">
        <center>
          <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">
          <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
          <img v-if="bbActive" src="/assets/buttons/list-button-diap.png" alt="Subject Buttons" @click="showBB">
          <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
        </center>
      </div>
        <img v-if="langId !== 'x'" class="flag" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">
        <img v-if="langId === 'x'" class="flag" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">
        <div class="divider"><center><hr></center></div>
      </div>
      <div class="object-container">
        <ObjectFiles v-bind:files="welcomeFiles" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
          <div class="welcome-title">{{ welcomeTitle }}</div>
          <div v-html="welcomeDescription" class="welcome-description"></div>
          <button class="btn-instruction" @click="showInstruction">{{ btnInstructionLabel }}</button>
          <button class="btn-start" @click="showIntro">{{ btnStartLabel }}</button>
      </div>
      <div class="footer">
        <hr>
        <div class="copy-right">&copy; Easycast Audio Tour</div>
      </div>
  </div>
</template>

<script>
import router from '../router';
import axios from 'axios';
// audio or images
import ObjectFiles from '../components/ObjectFiles';

export default {
  name: 'Welcome',
  components: {
    ObjectFiles
  },
  props: ["langId"],
  data() {
    return {
      browserOs: null,
      browserName: null,
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      welcomeData: [],
      welcomeFiles: [],
      welcomeTitle: '',
      welcomeDescription: '',
      btnInstructionLabel: '',
      btnStartLabel: '',
      browseActive: false,
      browseStart: false,
        languageX: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showIntro: function() {
      if(this.browseStart && this.browseActive) {
        // open first subject in audiotour
        axios.get(this.apiUrl + 'items?tags=audio-00&collection=' + this.apiCollection + '&key=' + this.apiKey)
            .then((res) => {
                  let objectId = res.data[0].id;
                  router.push('/object/'+this.langId+'/'+objectId);
                }
            )
            .catch(err => console.log(err));
      } else {
        router.push('/intro/' + this.langId);
      }
    },
    showInstruction: function() {
      router.push('/instruct/' + this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    }
  },
  created() {
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.browseActive = configData.browse;
      this.browseStart = configData.browse_start;
      this.groupsActive = configData.groups;
      this.apiUrl = configData.api;
      this.apiKey = configData.api_key;
      this.apiCollection = configData.api_collection;

      const pageLabels = configData.page_welcome[`${this.langId}`];
      const apiUrl = configData.api;
      const apiKey = configData.api_key;
      const apiCollection = configData.api_collection;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.btnInstructionLabel = pageLabels.label_instruction;
      this.btnStartLabel = pageLabels.label_start;
      this.languageX = configData.language_x;
      axios.get(apiUrl + 'items?collection='+apiCollection+'&tags=welcome&key=' + apiKey)
              .then((res) => {
                this.welcomeData = res.data;
                this.welcomeData[0].element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.welcomeTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.welcomeDescription = text_element.text;
                  }
                });
                // get audiofile(s)
                axios.get(apiUrl + 'files?item='+this.welcomeData[0].id+'&key=' + apiKey)
                        .then((resFiles) => {
                          this.welcomeFiles = resFiles.data;
                        })
                        .catch((err) => { console.log('error welcomefiles: ' + err) });
              })
              .catch((err) => { console.log('error welcomeData: ' + err) });
    })
    .catch((err) => console.log('error config: ' + err))
  },
  beforeDestroy() {
    this.welcomeFiles=null;
    delete this.welcomeFiles;
  }
}
</script>

<style scoped>
/* .intro {
    width: 100%;
} */
  .intro {
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .object-container {
    padding-top: 80px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: white;
  }
  .header .home {
    position: absolute;
    padding: 5px;
    height: 60px;
    top: 0px;
    left: 5%;
  }
  .header .home img {
    height: 60px;
    width: auto;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }
  .welcome-title {
    font-family: 'robotomedium', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .welcome-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .btn-instruction {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
  }
  .btn-start {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    color: white;
    text-align: center;
    background-color: black;
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
