<template>
    <div>
        <div v-bind:key="file.id" v-for="file in files">
            <ObjectFileItem
                v-bind:file="file" v-bind:lang="lang"
            />
        </div>
    </div>    
</template>

<script>
import ObjectFileItem from './ObjectFileItem';

export default {
    name: "ObjectFiles",
    props: ["files", "lang"],
    components: {
        ObjectFileItem
    }
}
</script>

<style scoped>
    .object-file {
        background: #f4f4f4;
        padding: 10px;
        border-bottom: 1px #ccc dotted;
    }
</style>